import { actions } from './actions';

const initialState = {
  success: null,
  message: '',
  loading: false,
  data: {},
  error: {},
  ride: {},
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case actions.SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        loading: false,
      };
    case actions.ERROR:
      return {
        ...initialState,
        ...action.payload,
      };
    case actions.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
