import {
  all, takeLatest, put, call, select,
} from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { distance, point } from '@turf/turf';
import getCarAngleUSA from 'utils/getCarAngle';
import axios from 'axios';
import flexiblePolyline from 'utils/flexiblePolyline';
import actions from './actions';
import { request } from '../../network/actions';
import API from '../api';

const { decode } = flexiblePolyline;

const { show } = API;

const url = `https://router.hereapi.com/v8/routes?apiKey=${process.env.REACT_APP_HERE_KEY}&transportMode=car&units=imperial&return=summary,polyline`;

function* SHOW(payload) {
  const { payload: { id } } = payload;
  yield put(request(show(id), actions.SHOW));
}

function AddHERE(ride) {
  const decodedRoute = decode(ride.data.route.route).polyline;

  if (isEmpty(decodedRoute) || decodedRoute.length < 2) {
    return ride;
  }

  let newUrl = '';
  newUrl += url;

  decodedRoute.forEach((el, index, arr) => {
    if (index === 0) {
      newUrl += `&origin=${el[0]},${el[1]}`;
    } else if (index === arr.length - 1) {
      newUrl += `&destination=${el[0]},${el[1]}`;
    } else {
      newUrl += `&via=${el[0]},${el[1]}`;
    }
  });

  return axios
    .get(newUrl)
    .then((data) => {
      let time = 0;
      const decodedPolylines = [];

      data.data.routes[0].sections.forEach((el) => {
        time += el.summary.duration;
        decodedPolylines.push(decode(el.polyline).polyline);
      });

      const toSend = {
        ...ride,
      };

      if (toSend.data.data.ride_status === 'Onride') {
        toSend.data.data.extra_status = `Dropoff in ${parseInt(time / 60, 10)}min`;
      }

      toSend.data.route.decodedPolylines = decodedPolylines;

      return toSend;
    })
    .catch((reason) => {
      console.log('Caught error', reason, ride);
      return ride;
    });
}

function AddDriverAngle(prevRide, ride) {
  const toSend = { ...ride };

  if (isEmpty(prevRide.data)) {
    toSend.data.route.angle = 0;

    return toSend;
  }

  if (!prevRide.data.route.driver_latlon) {
    toSend.data.route.angle = 0;

    return toSend;
  }

  if (!ride.data.route.driver_latlon) {
    toSend.data.route.angle = prevRide.data.route.angle || 0;

    return toSend;
  }

  const fromLng = prevRide.data.route.driver_latlon.lng;
  const fromLat = prevRide.data.route.driver_latlon.lat;

  const toLng = ride.data.route.driver_latlon.lng;
  const toLat = ride.data.route.driver_latlon.lat;

  const from = point([fromLng, fromLat]);
  const to = point([toLng, toLat]);
  const dist = distance(from, to);

  if (dist * 1000 > 5) {
    toSend.data.route.angle = getCarAngleUSA(fromLat, fromLng, toLat, toLng);
  } else {
    toSend.data.route.angle = prevRide.data.route.angle;
  }

  return toSend;
}

function* SUCCESS(payload) {
  const rideShow = payload.payload.data;

  if (!rideShow.success) {
    yield put({
      type: actions.SHOW_ERROR,
      payload: payload.payload.data,
    });

    return;
  }

  let newRide = yield call(AddHERE, rideShow);

  const prevRide = yield select((state) => state.rideShow);

  newRide = yield call(AddDriverAngle, prevRide, newRide);

  yield put({
    type: `${actions.EXPAND}`,
    payload: newRide,
  });
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SHOW, SHOW),
    takeLatest(actions.SHOW_SUCCESS, SUCCESS),
  ]);
}
