/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { isEqual } from 'lodash';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { useSelector, useDispatch } from 'react-redux';
import { remove } from 'redux/ride/cancel/actions';

import { ReactComponent as CancelIcon } from 'assets/cancelIcon.svg';

import styles from './SecondSlide.module.scss';

const SecondSlide = () => {
  const dispatch = useDispatch();

  const rideShow = useSelector((state) => state.rideShow, (left, right) => isEqual(left.data.data, right.data.data));
  const ride = rideShow.data.data;

  const showCancel = !['Cancelled', 'Completed'].includes(ride.ride_status);

  const timeline = () => {
    const res = [];

    res.push(ride.pickup.location_name);

    ride.stops.forEach((el) => {
      res.push(el.location_name);
    });

    return res;
  };

  const getDotClassName = (index, last) => {
    let result = 'dot';

    switch (index) {
      case 0:
        result += '_start';
        break;
      case last:
        result += '_end';
        break;
      default:
        result += '';
    }

    return result;
  };

  const dotContent = (index, last) => {
    if (index !== 0 && index !== last) {
      return <span className="dotContent">{index}</span>;
    }

    return null;
  };

  const CancelButton = () => {
    const [visible, setVisible] = React.useState(false);

    const handleCancel = () => {
      dispatch(remove(ride.ride_id));
      setVisible(false);
    };

    const Overlay = () => (
      <div className={styles.tooltipWrapper}>
        Are you sure?

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button className={styles.tooltipBtn_no} type="button" onClick={() => setVisible(false)}>
            No
          </button>

          <button className={styles.tooltipBtn_yes} type="button" onClick={handleCancel}>
            Yes
          </button>
        </div>
      </div>
    );

    return (
      <Tooltip placement="topLeft" overlay={<Overlay />} visible={visible}>
        <div className={styles.cancelRide} onClick={() => setVisible(true)}>
          <CancelIcon style={{ marginRight: '4px' }} />
          Cancel
        </div>
      </Tooltip>
    );
  };

  return (
    <div className={styles.wrapper}>
      <hr
        className={styles.hrStyle}
        style={{
          width: '100%',
          borderTop: '1px solid rgba(175, 175, 175, 0.5)',
        }}
      />

      <div className={styles.tripTimeline}>
        {timeline().map((point, index, array) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.trip} key={index}>
            {index !== 0 && <div className={styles.line_up} />}

            {index !== 0 && index !== array.length - 1 ? (
              <div className={styles.dot_stop}>{index}</div>
            ) : (
              <div className={styles[getDotClassName(index, array.length - 1)]}>
                {dotContent(index, array.length - 1)}
              </div>
            )}

            {index !== array.length - 1 && <div className={styles.line_down} />}

            <div style={{ lineHeight: '12px', margin: '4px 0px', overflow: 'hidden' }}>
              <div className={styles.addressTitle}>{point.split(',')[0].trim()}</div>
              <div className={styles.addressExtra}>{point.replace(', United States', '').trim()}</div>
            </div>
          </div>
        ))}
      </div>

      <hr className={styles.hrStyle} />

      {showCancel && <CancelButton />}
    </div>
  );
};

export default SecondSlide;
