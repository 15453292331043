import { useRef, useEffect } from 'react';
import { isEqual } from 'lodash';

function useCustomCompareMemo(value) {
  const ref = useRef(value);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useCustomCompareEffect(create, input) {
  // Tip to add "create" as dependency breaks the comparison
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(create, [useCustomCompareMemo(input)]);
}

export default useCustomCompareEffect;
