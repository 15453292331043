/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Icon } from '@iconify/react';
import vehicleCarProfileLtr20Regular from '@iconify/icons-fluent/vehicle-car-profile-ltr-20-regular';
import { show } from 'redux/flexible/show/actions';
import WaaveLogo from 'assets/flexible/waave_logo.png';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import Info from './info';

import style from './ride.module.scss';

dayjs.extend(localizedFormat);

const vehicleTypes = {
  waave: {
    id: 1,
    name: 'Waave',
    description: ['3 seats', 'all vehicles'],
  },
  sedan: {
    id: 2,
    name: 'Sedan',
    description: ['3 seats', 'easy entry'],
  },
  max: {
    id: 3,
    name: 'Max',
    description: ['6 seats', 'more space'],
  },
  accessible: {
    id: 4,
    name: 'Accessible',
    description: ['3 seats', 'wheelchair accessible'],
  },
  green: {
    id: 5,
    name: 'Green',
    description: ['3 seats', 'electric or hybrid vehicle'],
  },
  'eco,sedan': {
    // Filler ..not sure what IDs etc ....
    id: 6,
    name: 'Green Sedan',
    description: ['3 seats', 'electric or hybrid vehicle'],
  },
};

const manual = false;

const Ride = () => {
  const dispatch = useDispatch();

  const flexible = useSelector((state) => state.flexibleShow.data.flexibleRide);

  const stops = [flexible.pickup, ...flexible.stops.map((el) => el.address), flexible.drop_name];

  const generateDot = (index, last) => {
    if (index === 0) {
      return <div className={style.timeline_trip_dot_start} />;
    }

    if (index === last) {
      return <div className={style.timeline_trip_dot_end} />;
    }

    return <div className={style.timeline_trip_dot_stop} />;
  };

  const getAddressType = (index, last) => {
    if (index === 0) {
      return 'Pickup';
    }

    if (index === last) {
      return 'Drop-off';
    }

    return `Stop ${index}`;
  };

  const manualFetch = () => {
    dispatch(show(flexible._id));
  };

  return (
    <div className={style.page}>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto max-content' }}>
        <img src={WaaveLogo} alt="waave logo" className={style.logo} />

        {manual && <button type="button" onClick={manualFetch}>Fetch</button>}
      </div>

      <div className={style.title}>Your upcoming ride</div>

      <hr className={style.divider} />

      <div className={style.time}>
        <div className={style.time_title}>Your Ride is available until</div>

        <div className={style.time_date}>
          <CalendarIcon />
          <div className={style.time_date_dateTime}>{dayjs(flexible.pickup_date).format('ddd, MM/DD h:mm A')}</div>
        </div>
      </div>

      <div className={style.timeline}>
        {stops.map((address, index, array) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={style.timeline_trip}>
            {index !== 0 && <div className={style.timeline_trip_line_up} />}

            {generateDot(index, array.length - 1)}

            {index !== array.length - 1 && <div className={style.timeline_trip_line_down} />}

            <div className={style.timeline_trip_address}>
              <div className={style.timeline_trip_address_type}>
                {getAddressType(index, array.length - 1)}
              </div>

              <div className={style.timeline_trip_address_first}>
                {address.split(',')[0].replace(', United States', '').trim()}
              </div>

              <div className={style.timeline_trip_address_last}>
                {address.split(',').slice(1).join().replace(', United States', '')
                  .trim()}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={style.vehicle}>
        <Icon icon={vehicleCarProfileLtr20Regular} height="16" />

        <div className={style.vehicle_title}>Vehicle Type</div>

        <div className={style.vehicle_type}>
          {vehicleTypes[flexible.vehicle_car_type] || 'No type'}
        </div>

        <div className={style.vehicle_description}>
          {vehicleTypes[flexible.vehicle_car_type] ? (
            <>
              {vehicleTypes[flexible.vehicle_car_type].description[0]}
              {vehicleTypes[flexible.vehicle_car_type].description[1]}
            </>
          ) : 'No description'}
        </div>
      </div>

      <hr className={style.divider} />

      <div className={style.title}>Info</div>

      <Info />

    </div>
  );
};

export default Ride;
