import * as React from 'react';
import {
  Provider,
  useDispatch,
  useSelector,
} from 'react-redux';
import { Helmet } from 'react-helmet';
import { capitalize } from 'lodash';
import { setSetting } from 'redux/settings/actions';
import store from './redux/init';
import Share from './share';
import Flexible from './flexible';

const App = () => {
  const dispatch = useDispatch();
  const subdomain = useSelector((state) => state.settings.subdomain);

  const handleClick = (value) => {
    dispatch(setSetting({ subdomain: value }));
  };

  return (
    <>
      <Helmet titleTemplate="Waave | %s" title={capitalize(subdomain)} />

      {subdomain === 'main' && (
        <div
          style={{
            marginTop: '2rem',
            display: 'grid',
            rowGap: '2rem',
            justifyContent: 'center',
          }}
        >
          <div>Choose platform</div>

          <button type="button" onClick={() => handleClick('share')}>Share</button>

          <button type="button" onClick={() => handleClick('flex')}>Flex</button>
        </div>
      )}

      {subdomain === 'share' && <Share />}

      {subdomain === 'flex' && <Flexible />}
    </>
  );
};

const WrappedApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default WrappedApp;
