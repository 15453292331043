import { extraNetworkActions } from '../../network/actions';

const actions = {
  ...extraNetworkActions('CANCEL', 'ride/destroy'),
};

export default actions;

export function remove(id) {
  return {
    type: actions.CANCEL,
    payload: {
      id,
    },
  };
}

export function reset() {
  return {
    type: actions.CANCEL_RESET,
  };
}
