import * as React from 'react';

const Spinner = ({
  color = '#7f58af',
  size = 80,
  className = '',
  style,
}) => {
  const colorAsString = typeof color === 'string';

  // eslint-disable-next-line react/no-array-index-key
  const circles = [...Array(4)].map((_, index) => <div key={index} style={{ background: `${colorAsString ? color : color[index]}` }} />);

  let classNameFinal = 'lds-ellipsis';
  if (className !== '') {
    classNameFinal += ` ${className}`;
  }

  return (
    <div className={classNameFinal} style={{ ...style, width: size, height: size }}>
      {circles}
    </div>
  );
};

export default Spinner;
