import store from 'store';
import { actions } from './actions';

const STORED_SETTINGS = (storedSettings) => {
  const settings = {};

  Object.keys(storedSettings).forEach((key) => {
    const item = store.get(`app.settings.${key}`);

    if (key === 'locale') {
      // First: set as default 'en'
      settings.locale = storedSettings.locale;

      // Second: set language from URL
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const lang = urlParams.get('lang');

      if (lang) {
        settings.locale = lang;
      }

      // Third : set language from localStorage (user personal decision)
      // prevent issue with stored locale settings in the form of: en-US,
      // after switching to the simplified form (en)
      if (item && item.length === 2) {
        settings.locale = item;
      }
    } else {
      settings[key] = typeof item !== 'undefined' ? item : storedSettings[key];
    }
  });

  return settings;
};

const getSubdomain = () => {
  const setting = {
    subdomain: 'main',
  };

  const available = ['main', 'share', 'flex'];
  const subdomain = window.location.hostname.split('.')[0];

  if (available.includes(subdomain)) {
    setting.subdomain = subdomain;
  }

  return setting;
};

const initialState = {
  ...STORED_SETTINGS({
    isMobileView: 'false',
    locale: 'en',
  }),
  ...getSubdomain(),
};

Object.entries(initialState).forEach(([key, value]) => {
  store.set(`app.settings.${key}`, value);
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
