import * as React from 'react';
import L from 'leaflet';
import { useSelector } from 'react-redux';
import { Polyline, useMap } from 'react-leaflet';
import { isEmpty } from 'lodash';
import useEffectDeepCompare from 'utils/useEffectDeepCompare';

const Route = () => {
  const map = useMap();

  const rideShow = useSelector((state) => state.rideShow);

  const rideRoute = rideShow.data.route;

  const rideData = rideShow.data.data;

  useEffectDeepCompare(() => {
    if (!map) return;

    if (!['Arrived', 'Pickup'].includes(rideData.ride_status)) return;

    if (isEmpty(rideRoute.decodedPolylines)) return;

    const boundsOptions = { padding: [40, 200] };
    const bounds = new L.LatLngBounds();

    bounds.extend(rideRoute.decodedPolylines);

    map.fitBounds(bounds, boundsOptions);
  }, [rideShow]);

  return !isEmpty(rideRoute.decodedPolylines) ? (
    <Polyline
      key={rideRoute.route}
      positions={rideRoute.decodedPolylines}
      pathOptions={{ weight: 5, color: '#18AB47' }}
    />
  ) : null;
};

export default Route;
