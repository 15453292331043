import * as React from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import Markers from './Markers';
import Route from './Route';
import Driver from './DriverMarker';

const Map = () => {
  const rideShow = useSelector((state) => state.rideShow);

  const ride = rideShow.data.data;

  const ridePoints = [
    [ride.pickup.lat, ride.pickup.lng],
    ...ride.stops.map((el) => [el.lat, el.lng]),
  ];

  const boundsOptions = { padding: [20, 150] };
  const bounds = new L.LatLngBounds();

  ridePoints.forEach((el) => {
    bounds.extend([el[0], el[1]]);
  });

  return (
    <MapContainer
      key={ride.ride_id}
      style={{
        height: '100%',
        width: '100%',
      }}
      bounds={bounds}
      boundsOptions={boundsOptions}
    >
      <TileLayer
        attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        url="https://api.mapbox.com/styles/v1/{id}/tiles/512/{z}/{x}/{y}@2x?access_token={accessToken}"
        id="waaveus/ckhds711z065l1at05qy5ko2u"
        tileSize={512}
        zoomOffset={-1}
        detectRetina
        accessToken="pk.eyJ1Ijoid2FhdmV1cyIsImEiOiJja2hkcm5oazcwNTdtMnlybmR1M3NnMDh6In0.DDystHgLL-bimTkpygvYWg"
      />
      <Markers />

      <Driver />

      <Route />
    </MapContainer>
  );
};

export default Map;
