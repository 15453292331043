import * as React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { isEmpty } from 'lodash';
import useInterval from 'utils/useInterval2';
import { show } from 'redux/ride/show/actions';
import BottomSheet from 'components/BottomSheet';
import Spinner from 'components/Spinner';
import Map from 'components/Map';

import styles from './style.module.scss';

const ShowSpinner = () => (
  <div className={styles.loading}>
    <Spinner
      color={['#fc4614', '#0e5f8d', '#0f6740', '#f38601']}
      size={200}
    />
  </div>
);

const NoOrder = () => (
  <div className={styles.noRide}>
    <div className={styles.noRide_info}>
      No ride details
    </div>
  </div>
);

const Share = () => {
  const dispatch = useDispatch();
  const rideID = window.location.pathname.replace('/', '');
  const rideShow = useSelector((state) => state.rideShow);

  React.useEffect(() => {
    if (rideID) {
      dispatch(show(rideID));
    }
  }, [dispatch, rideID]);

  useInterval(
    () => {
      dispatch(show(rideID));
    },
    (rideShow.success !== null && rideShow.success !== false) ? 1000 * 10 : null,
  );

  return (
    <>
      {rideShow.loading && isEmpty(rideShow.data)
        ? <ShowSpinner />
        : (
          <>
            {!isEmpty(rideShow.data)
              ? (
                <>
                  <Map />
                  <BottomSheet />
                </>
              )
              : <NoOrder />}
          </>
        )}
    </>
  );
};

export default Share;
