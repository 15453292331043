import { createLayerComponent } from '@react-leaflet/core';
import LeafletDriftMarker from 'leaflet-drift-marker';

export default createLayerComponent(
  ({ position, ...options }, ctx) => {
    const instance = new LeafletDriftMarker(position, options);
    return { instance, context: { ...ctx, overlayContainer: instance } };
  },
  (marker, props, prevProps) => {
    // Enhanced marker with Rotate Marker
    if (props.rotationAngle !== prevProps.rotationAngle) {
      marker.setRotationAngle(props.rotationAngle);
    }
    if (props.rotationOrigin !== prevProps.rotationOrigin) {
      marker.setRotationOrigin(props.rotationOrigin);
    }

    // Original code below
    if (prevProps.position !== props.position && typeof props.duration === 'number') {
      marker.slideTo(props.position, {
        duration: props.duration,
        keepAtCenter: props.keepAtCenter,
      });
    }
    if (props.icon != null && props.icon !== prevProps.icon) {
      marker.setIcon(props.icon);
    }
    if (props.zIndexOffset != null && props.zIndexOffset !== prevProps.zIndexOffset) {
      marker.setZIndexOffset(props.zIndexOffset);
    }
    if (props.opacity != null && props.opacity !== prevProps.opacity) {
      marker.setOpacity(props.opacity);
    }
    if (marker.dragging != null && props.draggable !== prevProps.draggable) {
      if (props.draggable === true) {
        marker.dragging.enable();
      } else {
        marker.dragging.disable();
      }
    }
  },
);
