import actions from './actions';

const initialState = {
  success: null,
  message: '',
  loading: false,
  data: {},
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.CANCEL_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case actions.CANCEL_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        loading: false,
      };
    case actions.CANCEL_ERROR:
      return {
        ...initialState,
        ...action.payload,
      };
    case actions.CANCEL_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
