import {
  all, takeLatest, put,
} from 'redux-saga/effects';
import { request } from 'redux/network/actions';
import { actions } from './actions';
import { dispatch } from '../api';

function* DISPATCH({ payload: { id } }) {
  yield put(request(dispatch(id), actions.LOAD));
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD, DISPATCH),
  ]);
}
