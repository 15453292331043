export const actionNetwork = 'network/request';

export const request = (
  options,
  action,
  extra,
) => ({
  type: actionNetwork,
  payload: {
    options,
    action,
    extra,
  },
});

export default request;

export function networkActions(reducer) {
  return {
    LOAD: `${reducer}`,
    REQUEST: `${reducer}/request`,
    SUCCESS: `${reducer}/success`,
    ERROR: `${reducer}/error`,
    RESET: `${reducer}/reset`,
  };
}

function getPrefix(prefix, type) {
  const prefixUp = prefix.toUpperCase();
  return type ? `${prefixUp}_${type}` : prefixUp;
}

export function extraNetworkActions(prefix, reducer) {
  return {
    [getPrefix(prefix)]: `${reducer}`,
    [getPrefix(prefix, 'REQUEST')]: `${reducer}/request`,
    [getPrefix(prefix, 'SUCCESS')]: `${reducer}/success`,
    [getPrefix(prefix, 'ERROR')]: `${reducer}/error`,
    [getPrefix(prefix, 'RESET')]: `${reducer}/reset`,
  };
}
