import * as React from 'react';
import L from 'leaflet';
import { useSelector } from 'react-redux';
import CristiMarker from 'utils/CristiMarker';
import CarMarker from 'assets/carMarker.png';
import 'leaflet-rotatedmarker';

const carMarker = L.icon({
  iconUrl: CarMarker,
  iconSize: [16, 32],
});

const Driver = () => {
  const rideShow = useSelector((state) => state.rideShow);

  const rideRoute = rideShow.data.route;

  return rideRoute.driver_latlon && (
    <CristiMarker
      // key={ride.driver.id}
      icon={carMarker}
      // DriftMarker addon below
      position={[rideRoute.driver_latlon.lat, rideRoute.driver_latlon.lng]}
      // time in ms that marker will take to reach its destination
      duration={2000}
      // RotatedMarker addon below
      rotationAngle={rideRoute.angle}
      rotationOrigin="center center"
    />
  );
};

export default Driver;
