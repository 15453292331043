import actions from './actions';

const initialState = {
  success: null,
  message: '',
  loading: false,
  data: {},
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SHOW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.EXPAND:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actions.SHOW_ERROR:
      return {
        ...initialState,
        ...action.payload,
      };
    case actions.SHOW_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
