import {
  all, takeLatest, put,
} from 'redux-saga/effects';
import actions from './actions';
import { request } from '../../network/actions';
import API from '../api';

const { destroy } = API;

function* DESTROY(payload) {
  const { payload: { id } } = payload;
  yield put(request(destroy(id), actions.CANCEL));
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.CANCEL, DESTROY),
  ]);
}
