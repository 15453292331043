/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatch as flexDispatch } from 'redux/flexible/dispatch/actions';
import Spinner from 'components/Spinner';

import style from './info.module.scss';

const { REACT_APP_TRACK_URL } = process.env;

const url = REACT_APP_TRACK_URL;

const ShowSpinner = () => (
  <div className={style.loading}>
    <Spinner
      color={['#fc4614', '#0e5f8d', '#0f6740', '#f38601']}
      size={70}
    />
  </div>
);

const Info = () => {
  const dispatch = useDispatch();

  const flexible = useSelector((state) => state.flexibleShow.data.flexibleRide);

  const ride = useSelector((state) => state.flexibleShow.data.ride);

  const flexibleDispatchLoading = useSelector((state) => state.flexibleDispatch.loading);

  const flexibleDispatchRide = useSelector((state) => state.flexibleDispatch.ride);

  const handleRequest = () => {
    dispatch(flexDispatch(flexible._id));
  };

  // Redirect
  React.useEffect(() => {
    const noRedirect = ['No driver found', 'Cancelled', 'Completed'];

    if (ride && !noRedirect.includes(ride.data.ride_status)) {
      window.location.href = `${url}/${ride.data.ride_id}`;
    }
  }, [ride]);

  // Redirect immediately after dispatching
  React.useEffect(() => {
    if (flexibleDispatchRide && !isEmpty(flexibleDispatchRide)) {
      window.location.href = `${url}/${flexibleDispatchRide.data.ride_id}`;
    }
  }, [flexibleDispatchRide]);

  const PreRequest = () => {
    return (
      <div className={style.preRequest}>
        <div className={style.instructions}>

          &bull;

          <div>
            No extra stops or address changes
          </div>

          &bull;

          <div>
            Be ready outside when the driver arrives
            (Waiting time maximum 5 min)
          </div>

          &bull;

          <div>
            In case you need to change your ride, please
            contact your organization
          </div>
        </div>

        <div className={style.notice}>
          Request when you are ready to go!
        </div>

        <button
          type="button"
          className={style.button}
          onClick={handleRequest}
        >
          Request Now
        </button>

        <div className={style.terms}>
          By requesting the ride, I agree to the Waave Terms & Conditions
        </div>
      </div>
    );
  };

  const DriverNotFound = () => {
    return (
      <div className={style.driverNotFound}>
        <div className={style.notice}>
          No drivers available at this time
        </div>

        <div className={style.notice}>
          Please try again in a few minutes
        </div>

        <button
          type="button"
          className={style.button}
          onClick={handleRequest}
        >
          Request Again
        </button>

        <div className={style.terms}>
          By requesting the ride, I agree to the Waave Terms & Conditions
        </div>
      </div>
    );
  };

  const DriverCancel = () => {
    return (
      <div className={style.driverCancel}>
        <div className={style.notice}>
          The driver has cancelled the ride
        </div>

        <div className={style.notice}>
          Please try again in a few minutes
        </div>

        <button
          type="button"
          className={style.button}
          onClick={handleRequest}
        >
          Request Again
        </button>

        <div className={style.terms}>
          By requesting the ride, I agree to the Waave Terms & Conditions
        </div>
      </div>
    );
  };

  const GenInfo = () => {
    if (flexibleDispatchLoading || !isEmpty(flexibleDispatchRide)) {
      return <ShowSpinner />;
    }

    if (ride === null) {
      return <PreRequest />;
    }

    if (ride.data.ride_status === 'No driver found') {
      return <DriverNotFound />;
    }

    if (ride.data.ride_status === 'Cancelled') {
      return <DriverCancel />;
    }

    return <ShowSpinner />;
  };

  return <GenInfo />;
};

export default Info;
