/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Spinner from 'components/Spinner';

import noImageAvailable from 'assets/No_image_available.svg';
import { ReactComponent as VehicleNumber } from 'assets/vehicleNumber.svg';
import { ReactComponent as VehicleCar } from 'assets/vehicleCar.svg';
import { ReactComponent as UserIcon } from 'assets/userIcon.svg';
import { ReactComponent as CallPhoneIcon } from 'assets/callPhone.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as RightArrow } from 'assets/rightArrow.svg';

import styles from './FirstSlide.module.scss';

const { REACT_APP_FLEX_URL } = process.env;

const url = REACT_APP_FLEX_URL;

const rideStatusColor = {
  Requesting: '#454545',
  'No driver found': '#454545',
  Upcoming: '#454545',
  'Driver assigned': '#18AB47',
  Pickup: '#FFA800',
  Arrived: '#A17011',
  Onride: '#2976FA',
  Completed: '#18AB47',
  Cancelled: '#FF0000',
};

const ShowSpinner = () => (
  <div className={styles.loading}>
    <Spinner
      color={['#fc4614', '#0e5f8d', '#0f6740', '#f38601']}
      size={70}
    />
  </div>
);

const FirstSlide = () => {
  const rideShow = useSelector((state) => state.rideShow);
  const ride = rideShow.data.data;

  React.useEffect(() => {
    const redirect = ['No driver found', 'Cancelled'];

    // Redirect to flex website
    if (ride.flexible_ride_id !== null && redirect.includes(ride.ride_status)) {
      window.location.href = `${url}/${ride.flexible_ride_id}`;
    }
  }, [ride]);

  return (
    <div className={styles.wrapper}>

      {/* <div className={styles.dragBarTop} /> */}

      {ride.ride_status === 'Requesting'
        ? (
          <ShowSpinner />
        )
        : (
          <div className={styles.driverInfo}>

            <div className={styles.wrapperImg}>
              <img
                alt="driverImage"
                className={styles.driverImage}
                src={ride.driver.driver_image || 'invalid_link'}
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = noImageAvailable;
                }}
              />

              <img
                alt="carImage"
                className={styles.carImage}
                src={ride.driver.car_image || 'invalid_link'}
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = noImageAvailable;
                }}
              />
            </div>

            <div className={styles.wrapperLeftInfo}>
              <span className={styles.driverName}>
                <UserIcon style={{ marginRight: '4px' }} />
                {ride.driver.name || <span style={{ visibility: 'hidden' }}>empty</span>}
              </span>

              <span className={styles.vehicleNumber}>
                <VehicleNumber style={{ marginRight: '4px' }} />
                {ride.driver.driver_license || <span style={{ visibility: 'hidden' }}>empty</span>}
              </span>

              <span className={styles.vehicleModel}>
                <VehicleCar style={{ marginRight: '4px' }} />
                {ride.driver.vehicle_model || <span style={{ visibility: 'hidden' }}>empty</span>}
              </span>
            </div>

          </div>
        )}

      <StatusAndActionButtons ride={ride} />
    </div>
  );
};

const StatusAndActionButtons = ({ ride }) => {
  let showCallButton = true;

  const noShowCallArray = [
    'Requesting',
    'No driver found',
    'Upcoming',
    'Driver assigned',
  ];

  const showBookingArray = [...noShowCallArray];

  if (noShowCallArray.includes(ride.ride_status)) {
    showCallButton = false;
  }

  const CallButton = () => (
    <div
      className={styles.callDriver}
      onClick={(e) => {
        e.preventDefault();
        window.location.href = `tel://${ride.driver.phone}`;
      }}
    >
      <CallPhoneIcon style={{ marginRight: '4px' }} />
      Call Driver
    </div>
  );

  const ShowTime = () => {
    if (['Cancelled', 'Completed'].includes(ride.ride_status)) {
      return null;
    }

    const showBooking = showBookingArray.includes(ride.ride_status);

    if (showBooking) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CalendarIcon style={{ marginRight: '4px' }} />
          {dayjs(ride.booking_date).format('ddd, MM/DD, h:mm A')}
        </div>
      );
    }

    const showEstimate = !showBooking && ride.extra_status !== '';

    if (showEstimate) {
      const statusArray = ride.extra_status.split(' ');
      const minutesToAdd = parseInt(statusArray[statusArray.length - 1].slice(0, -3), 10);
      const now = dayjs().add(minutesToAdd, 'minutes');

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RightArrow style={{ marginRight: '4px' }} />
          {now.format('hh:mm A')}
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div style={{ color: rideStatusColor[ride.ride_status], fontWeight: 600 }}>
        {ride.ride_status === 'Requesting'
          ? `${ride.ride_status} - Looking for a driver`
          : ride.ride_status}

        {ride.extra_status && (
          <span
            style={{
              marginLeft: '2px',
              marginRight: '2px',
            }}
          >
            &#183;
          </span>
        )}
        <span
          style={{
            color:
              ride.ride_status === 'Arrived'
              && parseInt(ride.extra_status.split(' ')[1].slice(0, -3), 10) > 5
                ? 'red'
                : 'inherit',
          }}
        >
          {ride.extra_status}
        </span>
      </div>

      <hr className={styles.hrStyle} style={{ borderTop: `2px solid ${rideStatusColor[ride.ride_status]}` }} />

      <div className={styles.wrapperTimeCall}>
        <ShowTime />
        {showCallButton && <CallButton />}
      </div>

    </div>
  );
};

export default FirstSlide;
