import {
  all, takeEvery, put, call, race, delay,
} from 'redux-saga/effects';
import { api } from '../../utils/net';
import { actionNetwork } from './actions';

function* REQUEST(args) {
  const { payload: { options, action = 'missingAction', extra } } = args;

  try {
    yield put({
      type: `${action}/request`,
      payload: {
        options,
        extra,
      },
    });

    const { response, timeout } = yield race({
      response: call(api, options),
      timeout: delay(60 * 1000),
    });

    // handle timeouts

    if (timeout) {
      yield put({
        type: `${action}/error`,
        payload: {
          error: {
            message: 'Connection timeout!',
          },
        },
      });

      return;
    }

    // Get json data
    const data = yield call([response, response.json]);

    if (response.ok) {
      yield put({
        type: `${action}/success`,
        payload: {
          data: { ...data },
        },
      });

      return;
    }

    // oops, something happened

    yield put({
      type: `${action}/error`,
      payload: {
        error: { ...data },
      },
    });
  } catch (error) {
    const e = error;

    yield put({
      type: `${action}/error`,
      payload: {
        error: { ...e },
      },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actionNetwork, REQUEST),
  ]);
}
