import { get, postJson } from '../../utils/net';

const { REACT_APP_BASE_URL, REACT_APP_API_VER } = process.env;

const url = `${REACT_APP_BASE_URL}${REACT_APP_API_VER}`;

const show = (id) => get(`${url}/flexibleRideTrack/${id}`);

const dispatch = (id) => postJson(`${url}/flexibleRideTrack/${id}/dispatch`);

export {
  show,
  dispatch,
};
