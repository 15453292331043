import {
  all, takeLatest, put,
} from 'redux-saga/effects';
import { request } from 'redux/network/actions';
import { actions } from './actions';
import { show } from '../api';

function* SHOW({ payload: { id } }) {
  yield put(request(show(id), actions.LOAD));
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD, SHOW),
  ]);
}
