import { get } from '../../utils/net';

const { REACT_APP_BASE_URL, REACT_APP_API_VER } = process.env;

const url = `${REACT_APP_BASE_URL}${REACT_APP_API_VER}/rideTrack`;

const urlCancel = `${REACT_APP_BASE_URL}${REACT_APP_API_VER}/rideCancel`;

const show = (id) => get(`${url}/${id}`);
const destroy = (id) => get(`${urlCancel}/${id}`);

export default {
  show,
  destroy,
};
