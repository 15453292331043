import network from './network/sagas';

import settings from './settings/reducers';
import settingsSagas from './settings/sagas';

import rideShow from './ride/show/reducers';
import rideShowSagas from './ride/show/sagas';

import rideCancel from './ride/cancel/reducers';
import rideCancelSagas from './ride/cancel/sagas';

import flexibleShow from './flexible/show/reducers';
import flexibleShowSagas from './flexible/show/sagas';

import flexibleDispatch from './flexible/dispatch/reducers';
import flexibleDispatchSagas from './flexible/dispatch/sagas';

const lists = {
  reducers: {
    settings,
    rideShow,
    rideCancel,
    flexibleShow,
    flexibleDispatch,
  },
  sagas: [
    network(),
    settingsSagas(),
    rideShowSagas(),
    rideCancelSagas(),
    flexibleShowSagas(),
    flexibleDispatchSagas(),
  ],
};

export default lists;
