import * as React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import FirstSlide from './FirstSlide';
import SecondSlide from './SecondSlide';

import './bsOverride.css';

const Footer = () => (<div className="footerBar" />);

const NewBS = () => (
  <BottomSheet
    open
    blocking={false}
    footer={<Footer />}
    snapPoints={(props) => [props.headerHeight + 170, props.minHeight]}
    defaultSnap={(props) => {
      const { lastSnap, snapPoints } = props;
      return lastSnap ?? Math.max(...snapPoints);
    }}
  >
    <FirstSlide />
    <SecondSlide />
  </BottomSheet>
);

export default NewBS;
