import { networkActions } from '../../network/actions';

const actions = {
  ...networkActions('flexible/dispatch'),
};

function dispatch(id) {
  return {
    type: actions.LOAD,
    payload: {
      id,
    },
  };
}

function reset() {
  return {
    type: actions.RESET,
  };
}

export { actions, dispatch, reset };
