/* eslint-disable arrow-body-style */
import * as React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import useInterval from 'utils/useInterval2';
import { show } from 'redux/flexible/show/actions';
import { isEmpty, isEqual } from 'lodash';
import Spinner from 'components/Spinner';
import Ride from './ride';

import styles from './index.module.scss';

const ShowSpinner = () => (
  <div className={styles.loading}>
    <Spinner
      color={['#fc4614', '#0e5f8d', '#0f6740', '#f38601']}
      size={200}
    />
  </div>
);

const NoRide = () => (
  <div className={styles.noRide}>
    <div className={styles.noRide_info}>
      No ride details
    </div>
  </div>
);

const Flexible = () => {
  const dispatch = useDispatch();

  const flexibleID = window.location.pathname.replace('/', '');

  const flexibleShowLoading = useSelector((state) => state.flexibleShow.loading, (prev, next) => {
    return isEqual(prev, next);
  });

  const flexibleShowData = useSelector((state) => state.flexibleShow.data, (prev, next) => {
    return isEqual(prev, next);
  });

  const flexibleShowError = useSelector((state) => state.flexibleShow.error, (prev, next) => {
    return isEqual(prev, next);
  });

  React.useEffect(() => {
    if (flexibleID) {
      dispatch(show(flexibleID));
    }
  }, [dispatch, flexibleID]);

  useInterval(
    () => {
      dispatch(show(flexibleID));
    },
    (isEmpty(flexibleShowError)) ? 1000 * 10 : null,
  );

  return (
    <>
      {!isEmpty(flexibleShowData) && <Ride />}

      {flexibleShowLoading && isEmpty(flexibleShowData) && <ShowSpinner />}

      {!flexibleShowLoading && isEmpty(flexibleShowData) && <NoRide />}
    </>
  );
};

export default Flexible;
