import { extraNetworkActions } from '../../network/actions';

const actions = {
  ...extraNetworkActions('SHOW', 'ride/show'),
  EXPAND: 'ride/show/expand',
};

export default actions;

export function show(id) {
  return {
    type: actions.SHOW,
    payload: {
      id,
    },
  };
}

export function reset() {
  return {
    type: actions.SHOW_RESET,
  };
}
