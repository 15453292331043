import * as React from 'react';
import { useSelector } from 'react-redux';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';

import PinStart from 'assets/pin_start.svg';
import PinDropoff from 'assets/pin_dropoff.svg';
import PinStop from 'assets/pin_stop.svg';

const pickupMarker = L.icon({
  iconUrl: PinStart,
  iconAnchor: [18, 18],
});

const dropoffMarker = L.icon({
  iconUrl: PinDropoff,
  iconAnchor: [18, 18],
});

const stopMarker = L.icon({
  iconUrl: PinStop,
  iconAnchor: [18, 18],
});

const Markers = () => {
  const rideShow = useSelector((state) => state.rideShow);
  const ride = rideShow.data.data;

  const genPickupMarker = () => {
    if (!ride.pickup.lat || !ride.pickup.lng) {
      return null;
    }

    return (
      <Marker
        key="pickup"
        position={[ride.pickup.lat, ride.pickup.lng]}
        icon={pickupMarker}
      >
        <Popup>
          Pickup
          <br />
          {ride.pickup.location}
        </Popup>
      </Marker>
    );
  };

  const genStopsMarker = (stop, title, isDropoff) => {
    if (!stop.lat || !stop.lng) {
      return null;
    }

    return (
      <Marker
        key={title}
        position={[stop.lat, stop.lng]}
        icon={isDropoff ? dropoffMarker : stopMarker}
      >
        <Popup>
          {title}
          <br />
          {stop.location}
        </Popup>
      </Marker>
    );
  };

  return (
    <>
      {genPickupMarker()}

      {ride.stops.map((el, index, arr) => {
        const isDropoff = arr.length === index + 1;
        const title = isDropoff ? 'Dropoff' : `Stop ${index + 1}`;
        return genStopsMarker(el, title, isDropoff);
      })}
    </>
  );
};

export default Markers;
