export const actions = {
  SET: 'settings/set',
};

export function setSetting(payload) {
  return {
    type: actions.SET,
    payload,
  };
}
