import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { logger } from 'redux-logger';
import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from 'redux';
import lists from './lists';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

// Redux Logger
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_REDUX_LOGGER === 'true') {
  middlewares.push(logger);
}

const initReducers = combineReducers({
  ...lists.reducers,
});

const initSagas = () => function* rootSaga() {
  yield all([
    ...lists.sagas,
  ]);
};

const store = createStore(
  initReducers,
  compose(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(initSagas());

export default store;
